import React from 'react'

function Leadership() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 LeadershipBanner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Leadership</h1>
                        <p className='blockquote'>Who knows the way, goes the way, and shows the way.</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                    <div className='col-md-12 mt-5 text-center'>
                        <h3 className='text-uppercase text-mcolor'>LEADERSHIP</h3>
                        <p>Good Leaders are the true mentors to enable people to turn the resources they have into the power they need to make the change they want. Marquee Semiconductor is established by technologists in the semiconductor industry from different parts of the world who have translated their vision into reality.</p>
                    </div>
            </div>
        </div>

        <div className='container-fluid p-5 pt-0'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/purna.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div>
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>PURNA MOHANTY,</span> Founder and CEO</p>
                                    <p className='text-justify'>Purna brings in 28 years of experience and expertise in engineering, sales, business development, marketing, and operations, and connecting these specialties to build a successful business. His love for Silicon Valley and its technology vibe accompanied by a passion for propagating Silicon Valley’s innovative culture around the world drove him to start Marquee and later Signature IP with an R&D presence in the 'best kept secret' locations around the world. Prior to founding Marquee Semiconductor and Signature IP, Purna held the position of vice president engineering and operation at Tessolve USA. Purna brings in entrepreneurial vision to his companies – with his experience in co-founding or leading successful start-ups – ASIC Architect, Aims Technology, Kool Chip, Platys Communication, SmartSAN Network. Purna has provided engineering and operational leadership at established companies – Adaptec, Semtech, Broadcom, NXP and Tessolve. Purna holds 5 US patents in the area of SoC verification and design. Purna holds a BSEE from NIT Rourkela, India and MSEE from University of Toledo, OH, USA.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
        <div className='container-fluid p-5 bg-light'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Niranjan Tripathy,</span> Chief People Officer</p>
                                    <p className='text-justify'>With 30+ years of industry and research experience, Niranjan Tripathy brings in a wealth of knowledge in various technologies along with the superb people skill that is required for the company to scale from a start-up to a mid-size company. Prior to joining Marquee in mid-2021, Niranjan held an engineering leadership position in Fujitsu. Niranjan has worked at NASA USA on its Space Station Program. Niranjan had also worked Bhaba Atomic Research Center in India as a nuclear scientist. He possesses a Master’s Degree in Computer Science from UK Physics from Utkal University India and Computer Science from UK. Niranjan is passionate about “Learning How to Learn” and enjoy teaching as a hobby.</p>
                                </div>
                            </div>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/niranjan.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid p-5'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/hara.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div>
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Hara Prasad Mohanty,</span> Chief Financial Officer and Vice President of APAC Operations</p>
                                    <p className='text-justify'>Hara Prasad Mohanty brings over 24 years of extensive expertise in financial management, corporate and tax structuring, and business development within the FMCG and technology sectors. Since joining Marquee in 2019, he has played a pivotal role in driving strategic initiatives  and leading expansion efforts into the ASEAN and North Asia regions, thereby significantly enhancing the company’s competitive position. In addition  to his strategic contributions,  Hara actively promotes Marquee’s products and services to local governments and trade associations to support SME adoption. His prior experience encompasses senior finance and strategic positions at SSP, Sodexo, Sambaash, Minor Food Group, and Wine Connections. Hara is a Chartered Accountant and is affiliated with the Institute of Chartered Accountants of India and CPA Australia.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid p-5 bg-light'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            
                            <div className='col-md-9 mt-4 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Don Smith,</span> Silicon Architect</p>
                                    <p className='text-justify'>Don brings in 30+ years of experience in architecture and design of high
                                    performance and low power SoCs.  Don specializes in product-level system
                                    expertise that supports in defining state-of-the-art silicon for analog/mixed
                                    signal and digital design areas.</p>
                                    <p className='text-justify'>Prior to joining Marquee, Don held senior engineering positions in Hittite
                                    Microwave, Crimson Microsystems, Ciena Corp. and Oak Technology.
                                    Don holds an MS in Electrical Engineering from Stanford University and a
                                    BS in Physics from CSU Sacramento CA.</p>
                                </div>
                            </div>  
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/don.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid p-5'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center  p-5'>
                                <img src='./images/leaders/milo.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div> 
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Michael Loe Fernandez,</span> Design Engineering</p>
                                    <p className='text-justify'>Michael provides strategic leadership for the growth and direction of the company. He has 20+ years of experience in design engineering in the fields of consumer electronics, image processing, and AI/ML. His works on printers/cameras, compression engines, CMOS sensors, and digital controllers/processors have been successful in the market since 2006. He holds multiple image processing patents in both the United States and Japan. He graduated with a double degree in BS Physics and Computer Engineering from Ateneo de Manila University, Philippines. He is passionate about making a difference with technology.
                                    </p>
                                </div>
                                
                            </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='container-fluid p-5 bg-light'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Rocky Arce,</span> Software Engineering</p>
                                    <p className='text-justify'>Rocky possesses over 15 years of professional experience in designing and verifying complex silicon chips and systems including project and customer management. As a founding member of Marquee in the Philippines, he has been playing a significant role in building the company. Rocky drives several next generation activities in the company including RNN for speech recognition, CNN for Vision, automation platform tools. Prior to joining Marquee in 2020, Rocky was a principal engineer at Wave Computing working on the AI/ML SoCs. Rocky began his career with Canon working on pre-silicon and post-silicon verification, firmware development, C/RTL Co-simulation, and FPGA prototyping of image processing and computer vision products. Rocky holds a BS in Electronics and Communications from Ateneo de Manila University.</p>
                            
                                </div>
                                
                            </div>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/rockey.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/*
        <div className='container-fluid p-5'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/mukesh.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div> 
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Mukesh Sukla,</span> R&D and Programs</p>
                                    <p className='text-justify'>With 16 years of experience in research, academics and industry, Mukesh Sukla imbibes and drives a culture of innovation in Marquee. He oversees the effectiveness of all engineering programs in the company overseeing all internal processes, methodologies. Mukesh possesses a Bachelor’s in Electrical Engineering and Masters in Electronic & Comm from NIST, India. Currently, Mukesh is pursuing his PhD in VLSI Signal Processing at VSSUT, India with a research interest in approximate computing for low-power and accuracy trade-off for AI/ML applications.</p>
                                </div>
                            </div>
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
            */}
        

        

        <div className='container-fluid p-5 d-flex align-items-center justify-content-center'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/benny.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div> 
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Benedict Joseph Guevarra,</span> Simulation Engineering</p>
                                    <p className='text-justify'>As a founding member of the team, Benedict heads our design verification department. Benny has 12 years of industry experience in leading teams across the global sites, nurturing talent to become contributors in the company.  He envisions a design verification department that consistently provides timely and high-quality products and services; a department that is focused and result oriented; and a department that fosters highly motivated and skilled engineers. Benedict specializes in PCIe, DDR, Interconnect and CPUs. Prior to joining Marquee, Benedict worked at Wave Computing and Cannon. Benedict is a graduate of the University of Santo Tomas, Philippines with a Bachelor's degree in Electronics and Communications Engineering.</p>
                                </div>
                                    
                            </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        
        
        {/*<div className='container-fluid p-5 '>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/pradeep.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div> 
                            <div className='col-md-9 mt-4 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Pradeep Chimalapati,</span> Sales, Business Development</p>
                                    <p className='text-justify'>Pradeep holds the position of Director of Sales and Business Development at Marquee. Pradeep brings 20 years of experience and expertise in the semiconductor industry, holding several techno-managerial roles at UST, Samsung, and Nvidia, most recently at UST Global as Senior Engineering Manager supporting global customers in the semiconductor field. Prior to joining Marquee, Pradeep was based in Malaysia for over a decade, leading business development, product enablement, business operations, and ecosystem creation for niche skills for the APAC and EU regions. Pradeep is certified in Disruptive Strategy from Harvard Business School Online program. He possesses a Masters in Networking and Telecommunication from IIIT, Pune, India and a Bachelor of Technology in Electrical and Electronics from Berhampur University, India.</p>
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}

        

        {/*<div className='container-fluid p-5'>
            <div className='row '>
                <div className='col-md-12'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-center justify-content-center p-5'>
                                <img src='./images/leaders/wary.png' alt='Marquee Simiconductor' className='img-fluid rounded-circle'/>
                            </div>
                            <div className='col-md-9 d-flex align-items-center justify-content-center'>
                                <div>
                                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Nijwm Wary,</span> Technology Advisor</p>
                                    <p className='text-justify'>Dr. Wary comes to us with a decade of research experience in analog engineering with a focus on serdes technology.  Currently, he is an assistant professor in School of Electrical Science, IIT Bhubaneswar. He is a post-doctoral fellow at University of Toronto, Canada. He has received his Ph D, B Tech and M Tech in Electrical Engineering from IIT, Kharagpur, India. </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
  </div>*/}

        
        {/*<div className='container'>
            <div className='row'>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/purna.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div>
                <div className='col-md-8 mt-4 leadershipSection d-flex align-items-center justify-content-center'>
                    <div>
                        <p className='text-uppercase text-mcolor'> <span className='blockquote'>PURNA MOHANTY,</span> Founder and CEO</p>
                        <p className='text-justify'>Purna brings in 28 years of experience and expertise in engineering, sales, business development, marketing and operations, and connecting these specialties to build a successful business. His love for Silicon Valley and its technology vibe accompanied by a passion for propagating Silicon Valley’s innovative culture around the world drove him to co-found Marquee with a R&D presence around the world. Prior to co-founding Marquee Semiconductor, Purna held the position of vice president engineering and operation at Tessolve USA. Purna brings in entrepreneurial vision to the company – with his experience in co-founding or leading successful start-ups – ASIC Architect, Aims Technology, Kool Chip, Platys Communication, SmartSAN Network. Purna had provided engineering and operational leadership at established companies – Adaptec, Semtech, Broadcom, NXP and Tessolve. Purna holds 5 US patents in the area of SoC verification and design. Purna holds a BSEE from NIT Rourkela, India and MSEE from University of Toledo, OH, USA.</p>
                    </div>
                </div>
                
                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Niranjan Tripathy,</span> Chief People Officer</p>
                    <p className='text-justify'>With 30+ years of industry and research experience, Niranjan Tripathy brings in a wealth of knowledge in various technologies along with the superb people skill that is required for the company to scale from a start-up to a mid-size company. Prior to joining Marquee in mid-2021, Niranjan held an engineering leadership position in Fujitsu. Niranjan has worked at NASA USA on its Space Station Program. Niranjan had also worked Bhaba Atomic Research Center in India as a nuclear scientist. He possesses a Master’s Degree in Computer Science from UK Physics from Utkal University India and Computer Science from UK. Niranjan is passionate about “Learning How to Learn” and enjoy teaching as a hobby.</p>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/niranjan.jfif' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div>  

                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/pradeep.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div>
                <div className='col-md-8 mt-4 leadershipSection d-flex align-items-center justify-content-center'>
                    <div>
                        <p className='text-uppercase text-mcolor'> <span className='blockquote'>Pradeep Chimalapati,</span> Sales, Business Development</p>
                        <p className='text-justify'>Pradeep holds the position of Director of Sales and Business Development at Marquee. Pradeep brings 20 years of experience and expertise in the semiconductor industry, holding several techno-managerial roles at UST, Samsung, and Nvidia, most recently at UST Global as Senior Engineering Manager supporting global customers in the semiconductor field. Prior to joining Marquee, Pradeep was based in Malaysia for over a decade, leading business development, product enablement, business operations, and ecosystem creation for niche skills for the APAC and EU regions. Pradeep is certified in Disruptive Strategy from Harvard Business School Online program. He possesses a Masters in Networking and Telecommunication from IIIT, Pune, India and a Bachelor of Technology in Electrical and Electronics from Berhampur University, India.</p>
                    </div>
                </div>  

                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Mukesh Sukla,</span> R&D and Programs</p>
                    <p className='text-justify'>With 16 years of experience in research, academics and industry, Mukesh Sukla imbibes and drives a culture of innovation in Marquee in line with the company’s tagline – “Achieve the MAX within the MIN”. He oversees the effectiveness of all engineering programs in the company overseeing all internal processes, methodologies. Mukesh possesses a Bachelor’s in Electrical Engineering and Masters in Electronic & Comm from NIST, India. Currently, Mukesh is pursuing his PhD in VLSI Signal Processing at VSSUT, India with a research interest in approximate computing for low-power and accuracy trade-off for AI/ML applications.</p>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/mukesh.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div>  

                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/Milo.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div> 
                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Michael Loe Fernandez,</span> Design Engineering</p>
                    <p className='text-justify'>As a foundational member of the team, Michael provides both strategic and tactical leadership to the growth and direction of the company – not only in the areas of engineering and business, but also in the areas of project management and human resources. Michael brings to the table two decades of experience in digital design & verification in the fields of consumer electronics, image processing, and AI/ML. He specializes in front-end digital design and Formal Verification (FV). 
                    Michael holds multiple image processing patents in both the United States and Japan. His works on printers/cameras, compression engines, CMOS sensors, and digital controllers/processors have been successful in the market since 2006.
                    Milo graduated with a double degree in BS Physics and Computer Engineering from Ateneo de Manila University, Philippines. He is passionate about making a difference with technology.
                    </p>
                </div>

                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Rocky Arce,</span> Software and FPGA</p>
                    <p className='text-justify'>Rocky possesses over 15 years of professional experience in designing and verifying complex silicon chips and systems including project and customer management. As a founding member of Marquee in the Philippines, he has been playing a significant role in building the company. Rocky drives several next generation activities in the company including RNN for speech recognition, CNN for Vision, automation platform tools. Prior to joining Marquee in 2020, Rocky was a principal engineer at Wave Computing working on the AI/ML SoCs. Rocky began his career with Canon working on pre-silicon and post-silicon verification, firmware development, C/RTL Co-simulation, and FPGA prototyping of image processing and computer vision products. Rocky holds a BS in Electronics and Communications from Ateneo de Manila University. </p>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/rockey.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div> 

                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/benny.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div> 
                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Benedict Joseph Guevarra,</span> Verification</p>
                    <p className='text-justify'>As a founding member of the team, Benedict heads our design verification department. Benny has 12 years of industry experience in leading teams across the global sites, nurturing talent to become contributors in the company.  He envisions a design verification department that consistently provides timely and high-quality products and services; a department that is focused and result oriented; and a department that fosters highly motivated and skilled engineers. Benedict specializes in PCIe, DDR, Interconnect and CPUs. Prior to joining Marquee, Benedict worked at Wave Computing and Cannon. Benedict is a graduate of the University of Santo Tomas, Philippines with a Bachelor's degree in Electronics and Communications Engineering.</p>
                </div>
                
                <div className='col-md-8 mt-4'>
                    <p className='text-uppercase text-mcolor'> <span className='blockquote'>Dr Nijwm Wary,</span> Ph D, Technology Advisor</p>
                    <p className='text-justify'>Dr. Wary comes to us with a decade of research experience in analog engineering with a focus on serdes technology.  Currently, Dr. Wary is an assistant professor in School of Electrical Science, IIT Bhubaneswar. He is a post-doctoral fellow at University of Toronto, Canada. He has received his Ph D, B Tech and M Tech in Electrical Engineering from IIT, Kharagpur, India. </p>
                </div>
                <div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='./images/wary.jpg' alt='Marquee Simiconductor' className='img-fluid rounded-circle p-5'/>
                </div> 

            </div>
            </div>

  */}
        
    </>
  )
}

export default Leadership